<template>
  <b-sidebar
    id="add-new-room-sidebar"
    :visible="isAddNewRoomSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-room-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Update Room Details
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(formSubmitted)"
          @reset.prevent="resetForm"
        >
          <span class="font-weight-bold h5">{{ matchData.title }}</span> <br>
          <template v-if="matchData.at">
            {{ matchData.at.readable }}
          </template><br><br>
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Room Id"
            rules="required|numeric"
          >
            <b-form-group
              label="Room Id"
              label-for="room-id"
            >
              <b-form-input
                id="full-name"
                v-model="roomData.room_id"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="eg: 9941148765"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Room Password"
            rules="required"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="roomData.password"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Room Password"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update Room Details
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { numeric } from 'vee-validate/dist/rules'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

extend('numeric', numeric)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRoomSidebarActive',
    event: 'update:is-add-new-room-sidebar-active',
  },
  props: {
    isAddNewRoomSidebarActive: {
      type: Boolean,
      required: true,
    },
    matchData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      roomData: {
        room_id: '',
        password: 'game7',
      },
    }
  },
  setup(props) {
    const blankRoomData = {
      room_id: props.matchData.room_id,
      password: props.matchData.password,
    }
    const roomData = ref(JSON.parse(JSON.stringify(blankRoomData)))
    const resetuserData = () => {
      roomData.value = JSON.parse(JSON.stringify(blankRoomData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      roomData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    formSubmitted() {
      this.$store.dispatch('app-matches/updateMatch', {
        data: this.roomData,
        id: this.matchData.match_id,
      })
        .then(res => {
          if (res.data.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Match Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Match updated successfully',
              },
            })
            this.$emit('update:is-add-new-room-sidebar-active', false)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Match updation Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: 'Please try again after sometime',
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Oops! Match updation Failed',
              icon: 'BellIcon',
              variant: 'danger',
              text: 'Please try again after sometime',
            },
          }, {
            timeout: 3000,
          })
        })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    #add-new-user-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }
</style>
