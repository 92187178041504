<template>
  <div>
    <room-add
      v-if="isAddNewRoomSidebarActive"
      :is-add-new-room-sidebar-active.sync="isAddNewRoomSidebarActive"
      :match-data="currentMatchData"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <app-collapse>
      <app-collapse-item title="Filters">
        <match-list-filters
          :status-filter.sync="statusFilter"
          :status-options="statusOptions"
        />
      </app-collapse-item>
    </app-collapse>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0 mt-2"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search Title"
              />
              <b-button
                variant="primary"
                :to="{ name: 'add-match' }"
              >
                <span class="text-nowrap">Add Match</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchMatchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head(match_at)>
          Match Time
        </template>
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'view-match', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <span>
            <b-badge
              variant="light-success"
              style="margin-right: 2px;margin-bottom: 2px"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Update Room Detail'"
                class="btn-link cursor-pointer text-success"
                icon="KeyIcon"
                @click="updateRoomData(data)"
              />
            </b-badge>
            <b-badge
              variant="success"
              style="margin-right: 2px;margin-bottom: 2px"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Update Results'"
                class="btn-link cursor-pointer text-white"
                icon="GiftIcon"
                @click="editResult(data)"
              />
            </b-badge>
            <b-badge
              variant="warning"
              style="margin-right: 2px;margin-bottom: 2px"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Edit Match'"
                class="btn-link cursor-pointer text-white"
                icon="EditIcon"
                @click="editRecord(data)"
              />
            </b-badge>
            <b-badge
              variant="info"
              style="margin-right: 2px;margin-bottom: 2px"
            >
              <feather-icon
                v-b-tooltip.hover.top="'View Match'"
                class="btn-link cursor-pointer text-white"
                icon="EyeIcon"
                @click="viewRecord(data)"
              /> </b-badge>
            <b-badge
              variant="danger"
              style="margin-right: 2px;margin-bottom: 2px"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Delete Match'"
                class="btn-link cursor-pointer text-white"
                icon="TrashIcon"
                @click="confirmDeleteRecord(data)"
              />
            </b-badge>
          </span>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalMatchList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BBadge, BFormInput, BLink, BMedia, BPagination, BRow, BTable, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import store from '@/store'
import RoomAdd from '@/views/matches/list/RoomAdd.vue'
import matchStoreModule from '../matchStoreModule'
import useMatchList from './useMatchList'
import MatchListFilters from './MatchListFilters.vue'

export default {
  components: {
    MatchListFilters,
    RoomAdd,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,

    vSelect,
    /* eslint-disable */
            ToastificationContent,
            /* eslint-enable */
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isAddNewRoomSidebarActive',
    event: 'update:is-add-new-room-sidebar-active',
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = 'app-matches'

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(REFERRAL_APP_STORE_MODULE_NAME,
        matchStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME)
    })

    const statusOptions = []

    const isAddNewRoomSidebarActive = ref(false)

    const {
      fetchMatchList,
      tableColumns,
      perPage,
      currentPage,
      totalMatchList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      statusFilter,
    } = useMatchList()

    return {
      // Sidebar
      fetchMatchList,
      tableColumns,
      perPage,
      currentPage,
      totalMatchList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      isAddNewRoomSidebarActive,

      // Extra Filters
      statusOptions,
      statusFilter,
    }
  },
  data() {
    return {
      currentMatchData: {},
    }
  },
  methods: {
    updateRoomData(data) {
      this.currentMatchData = data.item
      this.isAddNewRoomSidebarActive = true
    },
    editResult(data) {
      this.$router.push(`/match/results/${data.item.match_id}`).catch(() => {})
    },
    editRecord(data) {
      this.$router.push(`/edit/match/${data.item.match_id}`).catch(() => {})
    },
    viewRecord(data) {
      window.open(`${process.env.VUE_APP_FRONTEND_URL}/pubg/${data.item.slug}`, '_blank')
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: 'Are you sure?',
        text: `You want to delete ${data.item.title}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteRecord(data.item.match_id)
        }
      })
    },
    deleteRecord(id) {
      const self = this
      this.$store.dispatch('app-matches/removeMatch', id)
        .then(res => {
          if (res.data.success) {
            self.refetchData()
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Match Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Match deleted successfully',
              },
            }, {
              timeout: 3000,
            })
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Match Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: 'Please try again after sometime',
              },
            }, {
              timeout: 3000,
            })
          }
        })
        .catch(err => err)
    },
  },
}
</script>

<style lang="scss" scoped>
    @import '@core/scss/vue/libs/vue-select.scss';

    .per-page-selector {
        width: 90px;
    }
</style>
